<template>
<div>
  <columns class="is-centered">
    <column class="is-6">
        <div class="is-flex is-align-items-center is-justify-content-center">
          <img class="guest-logo" src="/img/inspeckta-logo-white.svg" width="100" height="100" alt="inspeckta-logo">
        </div>
        <h2 class="title is-3 has-text-weight-light has-text-centered mb-6">Sign Up for Free</h2>
      <form>
        <columns>
          <column>
            <text-input
              classes="is-medium is-rounded"
              :value="user.name"
              @input="updateName"
              :error="$root.errors.name"
              required>
              First Name
            </text-input>
          </column>
          <column>
            <text-input
              classes="is-medium is-rounded"
              :value="user.surname"
              @input="updateSurname"
              :error="$root.errors.surname"
              required>
              Last Name
            </text-input>
          </column>
          <column>
            <text-input
              classes="is-medium is-rounded"
              :value="user.email"
              @input="updateEmail"
              :error="$root.errors.email"
              required>
              Email Address
            </text-input>
          </column>
        </columns>
        <columns>
          <column>
            <password-input
              classes="is-medium is-rounded"
              :value="user.password"
              @input="updatePassword"
              :error="$root.errors.password"
              required>
              Password
            </password-input>
          </column>
          <column>
            <password-input
              classes="is-medium is-rounded"
              :value="user.password_confirmation"
              @input="updatePasswordConfirmation"
              :error="$root.errors.password_confirmation"
              required>Confirm Password</password-input>
          </column>
        </columns>
        <columns>
          <column>
            <submit-button
              :working="working"
              @submit="signup"
              class="is-fullwidth mt-2 is-rounded is-medium">
              Sign Up
            </submit-button>
          </column>
        </columns>
        <columns>
          <column>
            <router-link class="is-flex" :to="{ name: 'signin' }">
              <icon icon="arrow-left"/>
              <span>Existing Users</span>
            </router-link>
          </column>
        </columns>
      </form>
    </column>
  </columns>
</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false
  }),

  methods: {
    ...mapMutations('auth', [
      'updateName',
      'updateSurname',
      'updateEmail',
      'updatePassword',
      'updatePasswordConfirmation'
    ]),
    signup() {
      this.working = true

      this.$store.dispatch('auth/signup').then(() => {
        this.working = false
        this.$router.push({
          name: 'signin'
        })
      }).catch(error => {
        this.working = false
        if (error.response.status === 429) {
          this.$alert({
            title: 'Threat Detected',
            message: 'Our systems have flagged your activity as suspicious. Please contact support for assistance.'
          })
        } else {
          this.$toast.error(`We're having trouble signing you up. Please try again or contact support.`)
        }
        
      })
    }
  },

  computed: mapGetters('auth', [
    'user'
  ])

}
</script>